import React from 'react';
import './Services.css'

function Services() {
    return (
        <section className='services' id='services'>
            <div className='services-wrapper'>
                <div className='services-main-content'>
                    <div className='services-paragraph-1'>
                        <p>
                            OUR SERVICES
                        </p>
                    </div>
                    <div className='services-paragraph-2'>
                        <p>
                            Choose from an unrivaled set of best-in-class website templates.
                            Customize the design to fit your personal style and professional needs.
                        </p>
                    </div>
                    <div className='services-tiles'>
                        <div className='services-tile-1'>
                            <div className='services-tile-1-paragraph-1'>
                                <p>
                                    Flexible portfolios
                                </p>
                            </div>
                            <div className='services-tile-1-paragraph-2'>
                                <p>
                                    Prasent finibus quam ullamcorper
                                    risus molesteie, et placerat leo cursus.
                                </p>
                            </div>
                        </div>
                        <div className='services-tile-2'>
                            <div className='services-tile-2-paragraph-1'>
                                <p>
                                    Blogging tools
                                </p>
                            </div>
                            <div className='services-tile-2-paragraph-2'>
                                <p>
                                    Nulla vel sem luctus leo pellentesque
                                    egestas eu vitae molestie, et placerat
                                    leo cursus.
                                </p>
                            </div>
                            <div />
                        </div>
                        <div className='services-tile-3'>
                            <div className='services-tile-3-paragraph-1'>
                                <p>
                                    ChatBot
                                </p>
                            </div>
                            <div className='services-tile-3-paragraph-2'>
                                <p>
                                    Nulla vel sem luctus leo pellentesque
                                    egestuas eu vitae sem.
                                </p>
                            </div>
                            <div/>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Services