import React from 'react';
import './BuildWithUs.css'
import { HashLink as Link } from 'react-router-hash-link'

function BuildWithUs() {
    return (
        <section className='build-with-us' id='build-with-us'>
            <div className='build-with-us-wrapper'>
                <div className='build-with-us-main-content'>
                    <div className='build-with-us-paragraph-1'>
                        <p>
                            Want to Build Great Product
                            With Us?
                        </p>
                    </div>
                    <Link to="#contact-us" className='build-with-us-button-link'>
                        <button className='build-with-us-button'>
                            CLICK HERE
                        </button>
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default BuildWithUs