import React from 'react';
import './Process.css'

function Process() {
    return (
        <section className='process' id='process'>
            <div className='process-wrapper'>
                <div className='process-main-content'>
                    <div className='process-main-text'>
                        <div className='process-main-text-paragraph-1'>
                            <p>
                                Process we follow
                            </p>
                        </div>
                        <div className='process-main-text-paragraph-2'>
                            <p>
                                Choose from an unrivaled set of best-in-class website templates. Customize the
                                design to fit your personal style and professional needs.
                            </p>
                        </div>
                    </div>
                    <div className='process-tiles'>
                        <div className='process-tile-1'>
                            <div className='process-tile-1-text'>
                                <div className='process-tile-paragraph-1'>
                                    <p>
                                        01
                                    </p>
                                </div>
                                <div className='process-tile-paragraph-2'>
                                    <p>
                                        Conversation
                                    </p>
                                </div>
                                <div className='process-tile-paragraph-3'>
                                    <p>
                                        Maecenas molestie est
                                        ligula, sit amet sollicitudin
                                        orci aliquam at. Vivamus
                                        placerat dolor arcu, nec.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='process-tile-2'>
                            <div className='process-tile-2-text'>
                                <div className='process-tile-paragraph-1'>
                                    <p>
                                        02
                                    </p>
                                </div>
                                <div className='process-tile-paragraph-2'>
                                    <p>
                                        Product Research
                                    </p>
                                </div>
                                <div className='process-tile-paragraph-3'>
                                    <p>
                                        Lacinia ex ultricies ut.
                                        Nullam lacinia erat eget
                                        odio fringilla, nec
                                        accumsan tortor accumsan. Proin
                                        malesuada, felis sit amet
                                        pulvinar hendrerit.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='process-tile-3'>
                            <div className='process-tile-3-text'>
                                <div className='process-tile-paragraph-1'>
                                    <p>
                                        03
                                    </p>
                                </div>
                                <div className='process-tile-paragraph-2'>
                                    <p>
                                        Evaluate Plans
                                    </p>
                                </div>
                                <div className='process-tile-paragraph-3'>
                                    <p>
                                        Elit elit tincidunt neque, id
                                        eleifend elit nulla ac
                                        turpis. Aenean scelerisque
                                        ipsum nec orci ultricies, eu
                                        tempus dui ultrices.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='process-tile-4'>
                            <div className='process-tile-4-text'>
                                <div className='process-tile-paragraph-1'>
                                    <p>
                                        04
                                    </p>
                                </div>
                                <div className='process-tile-paragraph-2'>
                                    <p>
                                        Best Results
                                    </p>
                                </div>
                                <div className='process-tile-paragraph-3'>
                                    <p>
                                        Pellentesque scelerisque
                                        tincidunt nulla quis
                                        condimentum. Curabitur ut
                                        eros vulputate enim
                                        euismod posuere a a
                                        sapien.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Process