import React from 'react';
import './ContactUs.css'

function ContactUs() {
    return (
        <section className='contact-us' id='contact-us'>
            <div className='contact-us-wrapper'>
                <div className='contact-us-main-content'>
                    <div className='contact-us-info'>
                        <div className='contact-us-paragraph-1'>
                            <p>
                                Contact Us
                            </p>
                        </div>
                        <div className='contact-us-paragraph-2'>
                            <p>
                                Choose from an unrivaled set of best-in-class website
                                templates. Customize the design to fit your needs.    
                            </p>     
                        </div>
                        <div className='contact-us-mail'>
                            <div className='contact-us-mail-icon'></div>
                            <div className='contact-us-mail-text'>
                                <div className='contact-us-mail-text-paragraph-1'>
                                    <p>
                                        E-mail
                                    </p>
                                </div>
                                <div className='contact-us-mail-text-link'>
                                    <p>
                                        mailaddress@gmail.com
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='contact-us-picture'></div>
                </div>
            </div>
        </section>
    )
}

export default ContactUs