import React from 'react';
import Navbar from '../navbar/Navbar';
import { HashLink as Link } from 'react-router-hash-link'
import './Header.css'


function Header() {
    return (
        <header className='header' id='header'>
            <div className='header-wrapper'>
                <Navbar />
                <div className='header-main-content'>
                    <div className='header-paragraph-1'>
                        <p>
                            CREATE WITH US
                        </p>
                    </div>
                    <div className='header-paragraph-2'>
                        <p>
                            The quickest way
                            to create your
                            ChatBot
                        </p>
                    </div>
                    <div className='header-paragraph-3'>
                        <p>
                            When users hover over a particular image, it draws
                            closer to the screen, which is a great trick for
                            boosting engagement.
                        </p>
                    </div>
                    <Link to='#services' className='start-now-button-link'>
                        <button className='header-start-now-button'>
                            START NOW
                        </button>
                    </Link>

                </div>
            </div>
        </header>
    )
}

export default Header