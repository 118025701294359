import React, { useEffect, useState } from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import './Navbar.css'

function Navbar() {
    const [click, setClick] = useState(false)
    const [, setButton] = useState(true)

    const handleClick = () => setClick(!click)
    const closeMobileMenu = () => setClick(false)

    const showButton = () => {
        if (window.innerWidth <= 1024) {
            setButton(false)
        } else {
            setButton(true)
        }
    }

    useEffect(() => {
        showButton();
    }, [])

    window.addEventListener('resize', showButton)

    return (
        <>
            <nav className='navbar'>
                <div className='navbar-container'>
                    <Link to="#header" className="navbar-logo" onClick={closeMobileMenu}>
                        <img src={'/images/navbar-logo.svg'} alt='Logo' />
                    </Link>
                    <div className='menu-icon' onClick={handleClick}>
                        <img src={'/images/burger-menu.svg'} alt='Burger Icon' className={click ? 'open' : 'closed'} />
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                            <Link to='#header' className='nav-links' onClick={closeMobileMenu}>
                                HOME
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='#services' className='nav-links' onClick={closeMobileMenu}>
                                SERVICES
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='#about-us' className='nav-links' onClick={closeMobileMenu}>
                                ABOUT US
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='#contact-us' className='nav-links' onClick={closeMobileMenu}>
                                CONTACT
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    )
}

export default Navbar
